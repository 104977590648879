import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { notification, Icon } from "antd";

let App;

if (process.env.NODE_ENV !== "production") {
  require("./development");
  require("./hot");
  App = require("./businessComponents/AppComponent").default;
} else {
  App = Loader.loadBusinessComponent("AppComponent");
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register({
  onUpdate: function(registration) {
    notification.open({
      message: "更新提示！",
      description: "缓存已更新，页面即将刷新！",
      duration: 0,
      icon: <Icon type="info-circle" style={{ color: "#108ee9" }} />,
      className: "server-worker-notify"
    });
    registration.waiting.postMessage({ type: "SKIP_WAITING" });

    setTimeout(() => {
      window.location.reload();
    }, 3 * 1000);
  }
});
